import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const Home =                  lazy(() => import('./MyComponents/pages/Home'));
const Membership =            lazy(() => import('./MyComponents/pages/Membership'));
const Reward =                lazy(() => import('./MyComponents/pages/Reward'));
const Search =                lazy(() => import('./MyComponents/pages/Search'));
const Profile =               lazy(() => import('./MyComponents/pages/Profile'));
const MembershipPlan=         lazy(() => import('./MyComponents/pages/MembershiPlan'));
const OfflineOutletList=      lazy(() => import('./MyComponents/pages/OfflineListView'));
const OnlineOutletList=       lazy(()=> import('./MyComponents/pages/OnlineListView'));
const OfflineOutletDetail=    lazy(()=> import('./MyComponents/pages/OfflineOutletDetail'));
const OnlineOutletDetails=    lazy(()=> import('./MyComponents/pages/OnlineOutletDetails'));
const Login=                  lazy(()=> import('./MyComponents/pages/Login'));
const Otp=                    lazy(()=> import('./MyComponents/pages/Otp'));
const Registration=           lazy(()=> import('./MyComponents/pages/Registration'));
const HotdealsListViewOffline=lazy(()=> import('./MyComponents/pages/HotdealsListViewOffline'));
const HotPicOutletViewOffline=lazy(()=> import('./MyComponents/pages/HotPicOutletViewOffline'));
const HotdealsListViewOnline= lazy(()=> import('./MyComponents/pages/HotdealsListViewOnline'));
const HotPicOutletViewOnline= lazy(()=> import('./MyComponents/pages/HotPicOutletViewOnline'));
const UpdateUser=             lazy(()=> import('./MyComponents/pages/UpdateUser'));
const LightningOutletsOffline=lazy(()=> import('./MyComponents/pages/LightningDealsOutletOffline'));
const LightningOutletOnline=  lazy(()=> import('./MyComponents/pages/LightningDealsOutletOnline'));
const Ratting=                lazy(()=> import('./MyComponents/pages/Ratting'));
const AboutOfflineOutlet=     lazy(()=> import('./MyComponents/pages/AboutOfflineOutlet'));
const HowItsWorks=            lazy(()=> import('./MyComponents/pages/HowItsWorks'));
const Faq=                    lazy(()=> import('./MyComponents/pages/Faq'));
const TermsAndCondition=      lazy(()=> import('./MyComponents/pages/TermsAndCondition'));



function App() {
  return (
    <>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Membership" element={<Membership />} />
            <Route path="/Reward/:type" element={<Reward />} />
            <Route path="/Search" element={<Search />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/MembershipPlan" element={<MembershipPlan />} />
            <Route path="/OfflineOutletList/:type" element={<OfflineOutletList />} />
            <Route path="/OnlineOutletList/:type" element={<OnlineOutletList />} />
            <Route path="/OfflineOutletDetail/:type" element={<OfflineOutletDetail />} />
            <Route path="/OnlineOutletDetails/:type" element={<OnlineOutletDetails />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Otp" element={<Otp />} />
            <Route path="/Registration" element={<Registration />} />
            <Route path="/HotdealsListViewOffline/:type" element={<HotdealsListViewOffline />} />
            <Route path="/HotPicOutletViewOffline/:type" element={<HotPicOutletViewOffline />} />
            <Route path="/HotdealsListViewOnline/:type" element={<HotdealsListViewOnline />} />
            <Route path="/HotPicOutletViewOnline/:type" element={<HotPicOutletViewOnline />} />
            <Route path="/UpdateUser" element={<UpdateUser />} />
            <Route path="/LightningOutletsOffline/:type" element={<LightningOutletsOffline/>}/>
            <Route path="/LightningOutletOnline/:type" element={<LightningOutletOnline/>}/>
            <Route path="/Ratting/:type" element={<Ratting/>}/>
            <Route path="/AboutOfflineOutlet/:type" element={<AboutOfflineOutlet/>}/>
            <Route path="/HowItsWorks/:type" element={<HowItsWorks/>}/>
            <Route path="/Faq/:type" element={<Faq/>}/>
            <Route path="/TermsAndCondition/:type" element={<TermsAndCondition/>}/>
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
